require('slick-carousel/slick/slick');

/*
 |--------------------------------------------------------------------------
 | Document Ready Function
 |--------------------------------------------------------------------------
 */

$(function () {
    'use strict';

    /**
     * Carousel slick
     * @link http://kenwheeler.github.io/slick
     */
    // Slider from home (4 elements)
    $('.slider-home').slick({
        slidesToShow: 4,
        slidesToScroll: 1,
        arrows: true,
        dots: false,
        responsive: [{
            breakpoint: 1280,
            settings: {
                slidesToShow: 3,
                arrows: false,
                autoplay: true,
                autoplaySpeed: 8000,
                initialSlide: 0,
            }
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 2.5,
                arrows: false,
                autoplay: true,
                autoplaySpeed: 8000,
                infinite: false,
            }
        },
        {
            breakpoint: 640,
            settings: {
                slidesToShow: 1.5,
                arrows: false,
                autoplay: true,
                autoplaySpeed: 6000,
                infinite: false,
            }
        }]
    });

    // Slider from single product (1 element and nav)
    $('.slider-single-for').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        fade: true,
        asNavFor: '.slider-single-nav'
    });

    $('.slider-single-nav').slick({
        slidesToShow: 6,
        slidesToScroll: 1,
        asNavFor: '.slider-single-for',
        dots: false,
        focusOnSelect: true
    });
});
