<template>
    <div class="grid md:grid-cols-12 grid-cols-1 gap-6 mt-9">
        <div class="md:col-span-4 col-span-1">
            <div class="relative text-black">
                <h3 class="uppercase md:text-xl text-lg">{{ titleText }}</h3>
            </div>
            <div class="map-items overflow-x-auto pr-2 mt-5">
                <div class="card-locations" v-for="dealer in dealers" @click="clickMarker(dealer)">
                    <div class="card-body">
                        <div class="card-content cursor-pointer flex items-start hover:bg-input duration-300 !pl-2">
                            <svg class="text-orange-100 flex-shrink-0 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                                <path fill-rule="evenodd" d="M11.54 22.351l.07.04.028.016a.76.76 0 00.723 0l.028-.015.071-.041a16.975 16.975 0 001.144-.742 19.58 19.58 0 002.683-2.282c1.944-1.99 3.963-4.98 3.963-8.827a8.25 8.25 0 00-16.5 0c0 3.846 2.02 6.837 3.963 8.827a19.58 19.58 0 002.682 2.282 16.975 16.975 0 001.145.742zM12 13.5a3 3 0 100-6 3 3 0 000 6z" clip-rule="evenodd" />
                            </svg>
                            <div class="ml-2">
                                <h6 class="uppercase text-sm font-medium">{{ dealer.name }}</h6>
                                <div class="card-text mt-1.5">
                                    <a :href="getAddressLink(dealer.address)" class="inline-block hover:text-orange-100" target="_blank">{{ dealer.address }}</a>
                                    <a :href="getPhone(dealer.phone)" class="inline-block hover:text-orange-100">Tel: {{ dealer.phone }}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="md:col-span-8 col-span-1">
            <div class="map" id="map" style="width: 100%; height: 450px;"></div>
        </div>
    </div>
</template>
<script>
    import { Loader } from '@googlemaps/js-api-loader'

    export default {
        props: {
            titleText: String,
            mapConfig: Object,
            apiKey: String,
            dealers: Object,
        },

        data() {
            return {
                google: null,
                map: null,
                clickFromUser: false,
            }
        },

        mounted() {
            const googleMapApi = new Loader({
                apiKey: this.apiKey,
                version: 'weekly',
                libraries: ['places'],
            });

            this.google = googleMapApi;
            this.initializeMap();
        },

        methods: {
            getPhone(phone) {
                return `${'tel:' + phone}`;
            },

            getLocation(location) {
                return { lat: parseFloat(location.lat), lng: parseFloat(location.lng) };
            },

            getAddressLink(address) {
                return `${'https://maps.google.com/?q=' + address.replace(/\s+/g, '+').toLowerCase()}`
                return ;
            },

            initializeMap() {
                this.google.load().then(async () => {
                    const { Map } = await google.maps.importLibrary("maps");
                    this.map = new Map(document.getElementById("map"), {
                        center: { lat: 32.1558032, lng: -111.0486127 },
                        zoom: 5,
                        mapId: '6c20d23b2c0e0e4f'
                    });

                    this.markers(this.map);
                });
            },

            async markers(map) {
                this.dealers.forEach((dealer) => {
                    this.showMarker(map, dealer)
                  });
            },

            clickMarker(dealer) {
                this.clickFromUser = true;
                this.showMarker(this.map, dealer);
            },

            async showMarker(map, dealer) {
                const { Marker } = await google.maps.importLibrary("marker");

                const position = this.getLocation(dealer.location);
                const marker = new Marker({
                      position,
                      map,
                      title: dealer.name,
                    });

                if (this.clickFromUser) {
                    this.clickFromUser = false;
                    this.infoMarker(dealer, map, marker);
                }

                marker.addListener("click", () => {
                    this.infoMarker(dealer, map, marker);
                });
            },

            async infoMarker(dealer, map, marker) {
                const { InfoWindow } = await google.maps.importLibrary("maps");

                const contentString =
                    '<div class="container px-6">' +
                        '<p><b>'+ dealer.name + '</b></p>' +
                        '<div class="mt-4">' +
                            '<a href="'+this.getAddressLink(dealer.address)+'" class="nav-link hover:text-orange-100" target="_blank">' + dealer.address +'</a><br><br>' +
                            '<a href="'+this.getPhone(dealer.phone)+'" class="inline-block hover:text-orange-100">Tel: '+dealer.phone+ '</a><br><br>' +
                            '<p>Go to:<br><a href="'+dealer.website+'" class="nav-link hover:text-orange-100" target="_blank">' + dealer.website
                        "</div>" +
                    "</div>";

                const info = new InfoWindow({
                    content: contentString,
                });

                map.setZoom(12);
                    map.setCenter(marker.getPosition());
                    info.close();
                    info.open({
                      anchor: marker,
                      map,
                    });
            }
        }
    }
</script>
